.login-container {
    width: 100%;
    height: 100%;
}

.bg-image {
    background-image: url("../../asset/images/bg-img-1.jpg");
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .8;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.login-form-container {
    width: 100%;
    min-width: 360px;
    background: white;
    border-radius: 10px;
    position: fixed;
    left: calc(50%);
    top: calc(45%);
    transform: translate(-50%, -50%);
    /*border: 1px solid black;*/
    padding: 20px;
    box-shadow: 0px 0px 10px 0px #888888;
}
